import hcOffcanvasNav from "hc-offcanvas-nav";

document.addEventListener("DOMContentLoaded", function () {
  const Nav = new hcOffcanvasNav("#main-nav", {
    disableAt: 1250,
    customToggle: ".burger",
    labelClose: "Закрыть",
    labelBack: "Назад",
  });
});

//fixed nav
function switchNav() {
  // const oldScroll = this.oldScroll || 0,
  //     newScroll = this.scrollY,
  //     isScrollDown = newScroll > 0;
  // isScrollDown = newScroll > oldScroll;

  document
    .querySelector(".page-header")
    .classList.toggle("sticky", window.scrollY > 0);

  // this.oldScroll = newScroll;
}

function throttle(callee, timeout) {
  let timer = null;
  return function perform(...args) {
    if (timer) return;
    timer = setTimeout(() => {
      callee(...args);
      clearTimeout(timer);
      timer = null;
    }, timeout);
  };
}

window.addEventListener("scroll", throttle(switchNav, 500));

//spyNav
const getId = (link) => {
  link.getAttribute("href");
};
let navIds = [];
const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      //console.log(entry);
      if (entry.isIntersecting) {
        document.querySelectorAll(".menu__link").forEach((link) => {
          const linkUrl = link.getAttribute("href").replace("/#", "");
          if (linkUrl === entry.target.id) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        });
      }
    });
  },
  {
    // rootMargin: "-10% 0px -10%",
    treshold: [0.25, 0.95],
  }
);

document.querySelectorAll(".menu__link").forEach((link) => {
  const id = link.getAttribute("href");
  const idtext = id.replace("/#", "");
  if (id.includes("/#") && window.location.pathname === "/") {
    navIds.push(idtext);
    link.addEventListener("click", (e) => {
      e.preventDefault();
      console.log(e);
      const hrefTarget = e.target.getAttribute("href").replace("/#", "");
      console.log(hrefTarget);
      if (hrefTarget) {
        const target = document.getElementById(hrefTarget);
        window.scrollTo({
          top: target.offsetTop - 150,
          behavior: "smooth",
        });
      }
    });
  }
});

navIds.forEach((el) => {
  const section = document.getElementById(el);
  if (section) {
    observer.observe(section);
  }
});
