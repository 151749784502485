import { Fancybox } from "@fancyapps/ui";
import IMask from "imask";

const phones = document.querySelectorAll('[type="tel"]');
const maskPhoneOpt = {
    mask: "+{375} (00) 000-00-00",
    lazy: false,
};
phones.forEach((el) => {
    const maskTel = IMask(el, maskPhoneOpt);
});

Fancybox.bind(".js-form-call", {});

document.addEventListener("submit", function (e) {
    const form = e.target;
    if (form.classList.contains("js-form-ajax")) {
        e.preventDefault();
        const submit = form.querySelector('button[type = "submit"]');
        const phoneInput = form.querySelector('input[type = "tel"]');
        if (phoneInput && phoneInput.value.includes("_")) {
          phoneInput.classList.add("error");
          phoneInput.addEventListener("click", (e) => {
            e.target.classList.remove("error");
          })
          return;
        }
        submit.disabled = true;
        fetch("/ajax/", {
            method: "post",
            body: new FormData(form),
        })
            .then((res) => res.json())
            .then((res) => {
                if (!res.status && res.error) {
                    form.innerHTML = res.output;
                    if (res.messages.length) {
                        Fancybox.show([{ src: res.messages, type: "html" }]);
                    }
                } else {
                    const error = form.querySelectorAll(".error");
                    error.forEach((err) => {
                        err.remove();
                    });
                    form.reset();
                    Fancybox.close();
                    submit.disabled = false;
                    Fancybox.show([{ src: res.output, type: "html" }]);
                }
            })
            .catch((err) => console.error(err));
    }
});
