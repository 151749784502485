import Swiper from "swiper";
import {
  Navigation,
  FreeMode,
  Scrollbar,
  Autoplay,
  Parallax,
} from "swiper/modules";
import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
// import Swiper and modules styles
// import "swiper/css";
// import "swiper/scss/navigation";
// import "swiper/scss/scrollbar";

// init Swiper:
const carousel = new Swiper(".carousel", {
  // configure Swiper to use modules
  modules: [Navigation, FreeMode, Scrollbar],
  slidesPerView: "auto",
  spaceBetween: 10,
  freeMode: true,
  autoplay: {
    delay: 2500,
  },
  navigation: {
    enabled: true,
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
    hide: false,
  },
});

const gallery = new Swiper(".gallery ", {
  // configure Swiper to use modules
  modules: [Navigation, Autoplay, Parallax],
  slidesPerView: 1,
  loop: true,
  parallax: true,
  speed: 1200,
  autoplay: {
    delay: 3000,
  },
  // navigation: {
  //     enabled: true,
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  // },
});

const reviews = new Swiper(".reviews__list", {
  // configure Swiper to use modules
  modules: [Scrollbar],
  slidesPerView: 3,
  spaceBetween: 20,
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
    hide: false,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  },
});

Fancybox.bind("[data-fancybox]:not(.fancybox__content)", {});
